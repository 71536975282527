import React from "react";
import {
  Button as ChakraButton,
  ButtonProps,
  IconButton as ChakraIconButton,
  IconButtonProps
} from "@chakra-ui/react";

import { usePersistSearchParams } from "app/persist-search-params";
import { BackIcon } from "./icons";

interface Props extends ButtonProps {
  primary?: boolean;
}
// Medmain custom Button: either a purple button or a white button for secondary actions
export const Button = ({ primary, ...props }: Props) => {
  // For primary, ensure colorScheme set to "primary".
  if (primary) {
    return <ChakraButton {...props} colorScheme="primary" />;
  }
  // For other situation, variant defaults to "outline", yet overrideable.
  return <ChakraButton {...props} variant={props.variant || "outline"} />;
};

export const IconButton = (props: IconButtonProps) => {
  return <ChakraIconButton {...props} variant={props.variant || "outline"} />;
};

export const RoundBackButton = (props: IconButtonProps) => {
  return <IconButton icon={<BackIcon />} isRound {...props} />;
};

// Back button used to go from an item page to the list page, reading the `from` URL parameter
export const RoundBackToListButton = (
  props: IconButtonProps & { pathname: string }
) => {
  const { showList } = usePersistSearchParams();
  const { pathname } = props;

  return (
    <IconButton
      icon={<BackIcon />}
      isRound
      fontSize={"1.5rem"}
      variant="outline"
      onClick={() => showList(pathname)}
      {...props}
    />
  );
};
